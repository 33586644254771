export const getActiveMenuItem = (page: ContentfulPage | null) => {
  if (!page) {
    return null;
  }
  if ('collectionPage' in page) {
    return page.collectionPage?.fullPath;
  }
  if ('parentPage' in page && page.parentPage && 'fullPath' in page.parentPage) {
    return page.parentPage.fullPath;
  }
  return page.fullPath;
};

