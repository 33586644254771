import React, { useContext } from 'react';

const MenuContext = React.createContext<
  Array<{
    headerMenuItems: [
      {
        fullPath: string;
        slug: string;
        title: string;
        menuTitle: string;
        shortTitle: string;
      }?,
    ];
    locale: string;
  }>
>([]);

export default MenuContext;

export function useMenuContext(locale: string) {
  const menuContext = useContext(MenuContext);
  const menuContextForLocale =
    menuContext.find((menuContextItem) => menuContextItem.locale === locale) || menuContext[0];
  return menuContextForLocale.headerMenuItems;
}
