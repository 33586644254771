import classNames from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDefaultLocalePath } from '../lib/getDefaultLocalePath';

function LangSelectorMobile({
  localePaths,
  currentLocale,
  onClick,
}: {
  localePaths: { locale: string; fullPath: string }[];
  currentLocale: string;
  onClick: () => void;
}) {
  return (
    <div>
      <p className="mb-1 text-gray-500">
        <FormattedMessage id="select-your-edition" />
      </p>
      {localePaths.map(({ locale, fullPath }) => (
        <p key={locale}>
          <input
            id={`edition-selector-${locale}`}
            type="radio"
            onChange={() => {
              navigate(getDefaultLocalePath(fullPath, locale));
              onClick();
            }}
            checked={locale === currentLocale}
            disabled={currentLocale === locale}
          />
          <label
            htmlFor={`edition-selector-${locale}`}
            className={classNames('pl-2 py-2 inline-block', {
              'text-gray-500': currentLocale === locale,
            })}
          >
            <FormattedMessage id={locale} />
          </label>
        </p>
      ))}
    </div>
  );
}

export default LangSelectorMobile;
