// react function component:
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';
import MenuContext from '../contexts/MenuContext';
import { SiteMetaContext } from '../contexts/SiteMetaContext';
import { NewsletterProvider } from './NewsletterProvider';

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const homePage = useStaticQuery(graphql`
    query homePage {
      allContentfulHomePage {
        nodes {
          id
          siteTitle: title
          locale: node_locale
          headerMenuItems {
            ... on Node {
              ... on ContentfulToplists {
                fullPath
                slug: url
                title
                menuTitle
                shortTitle
              }
              ... on ContentfulArticles {
                fullPath
                slug: url
                title
                menuTitle
                shortTitle
              }
              ... on ContentfulRecipes {
                fullPath
                slug: url
                title
                menuTitle
                shortTitle
              }
              ... on ContentfulFeatures {
                fullPath
                slug: url
                title
                menuTitle
                shortTitle
              }
              ... on ContentfulAbout {
                fullPath
                slug: url
                title
                menuTitle
                shortTitle
              }
              ... on ContentfulCollection {
                fullPath
                slug: url
                title
                menuTitle
                shortTitle
              }
            }
          }
        }
      }
    }
  `);

  const siteTitle = homePage.allContentfulHomePage.nodes.map((homePageNode) => ({
    siteTitle: homePageNode.siteTitle,
    locale: homePageNode.locale,
  }));

  const headerMenuItems = homePage.allContentfulHomePage.nodes.map((homePageNode) => ({
    headerMenuItems: homePageNode.headerMenuItems,
    locale: homePageNode.locale,
  }));

  return (
    <SiteMetaContext.Provider value={{ siteTitle }}>
      <MenuContext.Provider value={headerMenuItems}>
        <NewsletterProvider>{children}</NewsletterProvider>
      </MenuContext.Provider>
    </SiteMetaContext.Provider>
  );
};
