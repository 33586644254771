const getLocalePaths = (data: any, currentLocale: string) => {
  if (data?.localePaths) {
    if (currentLocale === 'en-US' && data.localePaths.nodes[0].fullPath === '/') {
      return data.localePaths.nodes.map((node: any) => ({
        locale: node.node_locale.split('-')[0],
        fullPath:
          node.node_locale.split('-')[0] === 'en' ? '/' : `/${node.node_locale}/budapest/`,
      }));
    }
    return data.localePaths.nodes.map((node: any) => ({
      locale: node.node_locale.split('-')[0],
      fullPath: node.fullPath,
    }));
  }
  const supportedLocales = ['en', 'es'];
  const currentFullPath = data?.page?.fullPath;
  if (!currentFullPath) {
    return [];
  }
  const currentLocaleShort = currentLocale.split('-')[0];
  const currentLocalePrefix = currentLocaleShort === 'en' ? '' : `/${currentLocaleShort}`;
  const hasCurrentLocale = currentFullPath.includes(`/${currentLocaleShort}/`);
  return supportedLocales.map((locale) => {
    if (locale === currentLocaleShort) {
      return { locale, fullPath: currentFullPath };
    }
    const localePrefix = locale === 'en' ? '' : `/${locale}`;
    const currentPath = hasCurrentLocale
      ? currentFullPath.replace(currentLocalePrefix, localePrefix)
      : `${localePrefix}${currentFullPath}`;
    const homePath = `/${locale}/budapest/`;
    const fullPath =
      currentLocaleShort === 'en' && currentPath === `${localePrefix}${currentFullPath}`
        ? homePath
        : currentPath;
    return {
      locale,
      fullPath,
    };
  });
};

export default getLocalePaths;
