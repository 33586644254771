import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './src/layouts/Layout';
import { LayoutProvider } from './src/providers/LayoutProvider';

declare global {
  interface Window {
    adsbygoogle: any;
    fbAsyncInit: any;
    dataLayer: any;
    FB: any;
  }
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <LayoutProvider>{element}</LayoutProvider>;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const onInitialClientRender = () => {
  // (function () {
  //   var l = document.createElement('script');
  //   l.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  //   l.async = true;
  //   head.appendChild(l);
  //   (window.adsbygoogle = window.adsbygoogle || []).push({
  //     google_ad_client: 'ca-pub-7484475176591772',
  //     enable_page_level_ads: true,
  //   });
  // })();

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: '1681516498804900',
      xfbml: true,
      version: 'v3.2',
    });
  };

  window.dataLayer = window.dataLayer || [];

  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  if (scrollbarWidth > 0) {
    document.documentElement.style.setProperty('--scrollbarWidth', scrollbarWidth + 'px');
  }
};
