import React from 'react';
import { useMenuContext } from '../contexts/MenuContext';
import Link from './link';

export default ({ locale }) => (
  <ul className="main-footer__menu">
    {useMenuContext(locale).map(({ slug, title, menuTitle, shortTitle }) => (
      <li key={slug} className="main-footer__item">
        <Link id={`nav-footer-${slug}`} to={`/${slug}/`} className="main-footer__menu-link">
          {menuTitle || shortTitle || title}
        </Link>
      </li>
    ))}
  </ul>
);
