'use server';
import React from 'react';
import { IntlProvider } from 'react-intl';
import Footer from '../components/footer';
import Header from '../components/header';
import Svg from '../components/icons';
import Navigation from '../components/Navigation';
import ScrollToTop from '../components/ScrollToTop';
import { useMessages } from '../hooks/useMessages';
import { getActiveMenuItem } from '../lib/getActiveMenuItem';

import LangSelector from '../components/LangSelector';
import getLocalePaths from '../lib/getLocalePaths';
import '../scss/global.scss';
import('../scss/common-deferred.scss');

const NewsletterModal = React.lazy(() => import('../components/newsletter-modal'));

const isSSR = typeof window === 'undefined';

const getLocale = (data: any, path: string) => {
  const dataLocale =
    data?.page?.node_locale || data?.page?.locale || data?.context?.locale || data?.locale;
  if (dataLocale) {
    return dataLocale;
  }
  const pathLocale = path.split('/')[1];
  if (pathLocale.length === 2) {
    return pathLocale;
  }
  return 'en-US';
};

const Layout = ({
  children,
  data,
  path,
}: {
  children: React.ReactNode;
  data: {
    page: ContentfulPage | null;
    context: { locale: string };
    localePaths: { nodes: { node_locale: string; fullPath: string }[] } | null;
  };
  path: string;
}) => {
  const activeMenuItem = getActiveMenuItem(data?.page);
  const locale = getLocale(data, path);
  const messages = useMessages({ locale: locale.split('-')[0] });
  const localePaths = getLocalePaths(data, locale);
  return (
    <IntlProvider locale={locale} messages={messages}>
      <Svg />
      <div className="4xl:sticky 4xl:top-0 4xl:grid 4xl:grid-rows-auto 4xl:grid-cols-[auto,1fr] 4xl:z-10 bg-white">
        <LangSelector
          className="4xl:col-span-full"
          paths={localePaths}
          currentLocale={locale.split('-')[0]}
        />
        <Header locale={locale} />
        <Navigation
          activeMenuItem={activeMenuItem || ''}
          localePaths={localePaths}
          currentLocale={locale.split('-')[0]}
        />
      </div>
      {children}
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <NewsletterModal />
        </React.Suspense>
      )}
      <ScrollToTop />
      <Footer locale={locale} />
    </IntlProvider>
  );
};

export default Layout;
