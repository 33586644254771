import cx from 'classnames';
import React from 'react';

import { scrolltopButton } from '../scss/modules/ScrollToTop.module.scss';
import { button, buttonNoDecoration } from '../scss/modules/button.module.scss';
import StickToBottom from './StickToBottom/StickToBottom';

const onClick = () =>
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

const ScrollToTop = () => (
  <StickToBottom wrapperClassName="scroll-to-top">
    <button
      type="button"
      className={cx(
        button,
        buttonNoDecoration,
        scrolltopButton,
        'flex justify-center items-center',
      )}
      onClick={onClick}
      data-scroll-target="html"
      aria-label="Click to scroll to top"
    >
      <svg role="img" aria-labelledby="scrolltitle">
        <title id="scrolltitle">Arrow pointing up</title>
        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-scrolltop" />
      </svg>
    </button>
  </StickToBottom>
);

export default ScrollToTop;
