import classNames from 'classnames';
import { bool, func } from 'prop-types';
import React from 'react';

import Link from './link';

import { FormattedMessage, useIntl } from 'react-intl';
import { useMenuContext } from '../contexts/MenuContext';
import { button, buttonNoDecoration } from '../scss/modules/button.module.scss';
import * as mobilenav from '../scss/modules/mobile-nav.module.scss';

function MobileMenuBar({
  isMainNavOpen,
  isSearchOpen,
  onMenuItemClick,
  onMoreClick,
  onSearchClick,
  activeMenuItem = '',
}) {
  const { locale } = useIntl();
  const menu = useMenuContext(locale).slice(0, 2);

  return (
    <div className={`${mobilenav.mobileNav} js-scroll-header`}>
      <ul role="menubar" className={mobilenav.mobileNav__menu}>
        {menu.map(({ fullPath, slug, title, menuTitle, shortTitle }) => (
          <li
            key={slug}
            role="menuitem"
            className={classNames(mobilenav.mobileNav__item, 'flex justify-stretch', {
              [mobilenav.active]: RegExp(`^/${slug}/`).test(activeMenuItem),
            })}
          >
            <Link to={fullPath} onClick={onMenuItemClick} className="text-center w-full">
              {menuTitle || shortTitle || title}
            </Link>
          </li>
        ))}
        <li className={classNames(mobilenav.mobileNav__item, 'flex justify-stretch')}>
          <button
            id="mobile-menu-search-trigger"
            onClick={onSearchClick}
            className={`${button} ${buttonNoDecoration} text-center w-full`}
            type="button"
            aria-controls="search-menu"
            aria-expanded={isSearchOpen}
          >
            <FormattedMessage id="search" defaultMessage={'Search'} />
          </button>
        </li>
        <li className={classNames(mobilenav.mobileNav__item, 'flex justify-stretch')}>
          <button
            id="mobile-menu-more-trigger"
            onClick={onMoreClick}
            className={`${button} ${buttonNoDecoration} ${mobilenav.mobileNav__more} text-center w-full`}
            type="button"
            aria-controls="nav-menu"
            aria-expanded={isMainNavOpen}
          >
            More
          </button>
        </li>
      </ul>
    </div>
  );
}

MobileMenuBar.defaultProps = {
  isMainNavOpen: false,
};

MobileMenuBar.propTypes = {
  onMenuItemClick: func.isRequired,
  onMoreClick: func.isRequired,
  isMainNavOpen: bool,
};

export default MobileMenuBar;
