declare global {
  interface Window {
    dataLayer: any[];
    ga: Function;
  }
}

export default function (eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number | string) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'GAEvent',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    });
  } else if (typeof window.ga === 'function') {
    window.ga('gtm1.send', 'event', eventCategory, eventAction, eventLabel, eventValue);
  }
}
