// extracted by mini-css-extract-plugin
export var button = "button-module--button--16369";
export var buttonBlack = "button-module--button--black--821b7";
export var buttonBlue = "button-module--button--blue--bd40a";
export var buttonDark = "button-module--button--dark--e75ba";
export var buttonFullwidth = "button-module--button--fullwidth--f5c9c";
export var buttonGhostBlue = "button-module--button--ghost-blue--7528e";
export var buttonGreen = "button-module--button--green--61c4e";
export var buttonLarge = "button-module--button--large--b7987";
export var buttonMedium = "button-module--button--medium--f225a";
export var buttonNoDecoration = "button-module--button--no-decoration--8951c";
export var buttonNoText = "button-module--button--no-text--78528";
export var buttonRed = "button-module--button--red--ed86c";
export var buttonSecondary = "button-module--button--secondary--ddb21";
export var buttonSmall = "button-module--button--small--98443";
export var buttonSvgIcon = "button-module--button--svg-icon--872f4";
export var buttonUppercase = "button-module--button--uppercase--2f86a";
export var buttonXlarge = "button-module--button--xlarge--9d473";