import React from 'react';
import { string } from 'prop-types';

const TabTrap = ({
  nextId,
  nextSelector,
  onFocus,
}) => (
  <span
    role="button"
    tabIndex="0"
    onFocus={() => {
      if (nextId) {
        document.getElementById(nextId).focus();
      }
      else if (nextSelector) {
        document.querySelector(nextSelector).focus();
      }
      if (onFocus) {
        onFocus();
      }
    }}
    aria-label="&nbsp;"
  />
);

TabTrap.propTypes = {
  nextId: string,
  nextSelector: string,
};

export default TabTrap;
