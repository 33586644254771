import { RefObject, useCallback, useEffect } from 'react';

const useClickOutside = (
  ref: RefObject<HTMLElement>,
  onClickOutside: () => void
): void => {
  const isOutside = (element: EventTarget | null): boolean => {
    return !ref.current || !ref.current.contains(element as Node);
  }
  const onClick = useCallback((event: MouseEvent): void => {
    if (isOutside(event.target)) {
      onClickOutside();
    }
  }, [ref.current, onClickOutside]);

  useEffect(() => {
    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, [onClickOutside]);
};

export default useClickOutside;
