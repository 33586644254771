import React from 'react';
import { useIntl } from 'react-intl';
import SearchInput from './SearchInput';

const SearchContainer = React.lazy(() => import('./SearchContainer'));

const LazySearch = ({
  className = '',
  onResultClick,
}: {
  className?: string;
  onResultClick: () => void;
}) => {
  const { locale } = useIntl();
  const indices_en = [
    {
      name: process.env.GATSBY_ALGOLIA_INDEX_NAME || 'offbeat',
      title: 'Articles',
    },
  ];
  const indices_es = [
    {
      name: `${process.env.GATSBY_ALGOLIA_INDEX_NAME}_es` || 'offbeat_es',
      title: 'Artículos',
    },
  ];
  return (
    <React.Suspense fallback={<SearchInput onChange={() => {}} onFocus={() => {}} />}>
      <SearchContainer
        className={className}
        locale={locale}
        indices={locale === 'en-US' ? indices_en : indices_es}
        selectedIndex={locale === 'en-US' ? indices_en[0].name : indices_es[0].name}
        onResultClick={onResultClick}
      />
    </React.Suspense>
  );
};

export default LazySearch;
