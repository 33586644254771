import cx from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  button,
  buttonGhostBlue,
  buttonSvgIcon,
  buttonUppercase,
  buttonXlarge,
} from '../scss/modules/button.module.scss';

export default function ({ onClick }) {
  return (
    <button
      type="button"
      className={cx([
        button,
        buttonUppercase,
        buttonXlarge,
        buttonGhostBlue,
        buttonSvgIcon,
        'newsletter__button newsletter__button--header js-newsletter-modal-open js-modal-open',
      ])}
      onClick={onClick}
    >
      <svg role="presentation" viewBox="0 0 22 16" aria-labelledby="navmailtitle navmaildesc">
        <title id="navmailtitle">Mail</title>
        <desc id="navmaildesc">Icon of a letter</desc>
        <use xlinkHref="#icon-mail" />
      </svg>
      <FormattedMessage id="newsletter-cta-subscribe-button" defaultMessage={'Subscribe'} />
    </button>
  );
}
