import cx from 'classnames';
import React from 'react';
import {
  floatingFooter,
  stickToBottomContainer,
  stickToBottomWrapper,
} from './StickToBottom.module.scss';

export default function StickToBottom({
  children,
  bottomOffset = 0,
  isFooter = false,
  wrapperClassName = '',
}) {
  const wrapperCompoundClassName = cx([
    stickToBottomWrapper,
    wrapperClassName,
    isFooter && floatingFooter,
  ]);
  const wrapperStyle = {};
  if (bottomOffset) {
    wrapperStyle['--stb-bottom-offset'] = `${bottomOffset}px`;
  }
  return (
    <div className={wrapperCompoundClassName} style={wrapperStyle}>
      <div className={stickToBottomContainer}>{children}</div>
    </div>
  );
}
