import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useMenuContext } from '../contexts/MenuContext';
import LangSelectorMobile from './LangSelectorMobile';
import Link from './link';

const isSSR = typeof window === 'undefined';

function MenuBar({ onClick, activeMenuItem = '', localePaths, currentLocale }) {
  const { locale } = useIntl();
  return (
    <ul role="menubar" className="main-nav__list 4xl:flex 4xl:items-center">
      {useMenuContext(locale).map(({ fullPath, slug, title, menuTitle, shortTitle }, index) => (
        <li
          key={slug}
          role="menuitem"
          className={classNames('main-nav__item', 'main-nav__item--isLink', {
            active:
              !isSSR &&
              RegExp(`^${locale === 'en-US' ? '' : `/${locale}`}/${slug}/`).test(activeMenuItem),
            'hidden 4xl:inline-block': index <= 1,
          })}
        >
          <Link id={`nav-${slug}`} to={fullPath} onClick={onClick}>
            {menuTitle || shortTitle || title}
          </Link>
        </li>
      ))}
      <li className="list-item 4xl:hidden">
        <LangSelectorMobile
          localePaths={localePaths}
          currentLocale={currentLocale}
          onClick={onClick}
        />
      </li>
      <a
        href="https://www.instagram.com/offbeatbudapest/"
        target="_blank"
        rel="noopener noreferrer"
        className="main-nav__social"
        title="Instagram profile"
      >
        <svg role="presentation" aria-label="Instagram profile">
          <title>Instagram</title>
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-instagram" />
        </svg>
      </a>
      <a
        href="https://www.facebook.com/offthebeatbudapest/"
        target="_blank"
        rel="noopener noreferrer"
        className="main-nav__social"
        title="Facebook page"
      >
        <svg role="presentation" aria-label="Facebook page">
          <title>Facebook</title>
          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-facebook" />
        </svg>
      </a>
    </ul>
  );
}

MenuBar.defaultProps = {
  onClick: () => {},
};

MenuBar.propTypes = {
  onClick: PropTypes.func,
};

export default MenuBar;
