import React, { ReactNode, useState } from "react";

export const NewsletterContext = React.createContext(false);

export const NewsletterOpenerContext = React.createContext(
  (value: boolean) => {},
);

export const NewsletterProvider = ({ children }: { children: ReactNode }) => {
  const [isNewsletterOpen, setNewsletterOpen] = useState(false);

  return (
    <NewsletterContext.Provider value={isNewsletterOpen}>
      <NewsletterOpenerContext.Provider value={setNewsletterOpen}>
        {children}
      </NewsletterOpenerContext.Provider>
    </NewsletterContext.Provider>
  );
};
